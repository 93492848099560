import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Menu, Dropdown, Button, Popconfirm, Skeleton } from 'antd';
import { TaskViewVisualStore } from '../stores';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { PackagePreviewModel } from '../types';

type Props = {
    tasksStore: TaskViewVisualStore;
    taskId: string;
    disabled: boolean;
    onDownload: (attachmentId: string | undefined) => void;
    onPreview: (attachmentId: string | undefined) => void;
    onSendTaskToUsers?: () => void;
    onRemoveAttachements: () => void;
    onRemoveTask?: () => void;
    label?: string | JSX.Element;
    type?: 'default' | 'dashed' | 'primary' | 'ghost' | 'link' | undefined;
    size?: SizeType;
    btnStyle?: React.CSSProperties;
    pkg?: PackagePreviewModel;
    selectedPackages?: PackagePreviewModel[];
    isTaskMenu?: boolean
};

const TaskApplicationsMenu: React.FC<Props> = ({
    tasksStore,
    label,
    btnStyle,
    disabled,
    onPreview,
    onDownload,
    onSendTaskToUsers,
    onRemoveAttachements,
    onRemoveTask,
    size = undefined,
    type = 'primary',
    isTaskMenu = true,
    pkg = undefined}) => {

    const availableActions = pkg ? tasksStore.getAvailableAttachmentActions(pkg) : [];

    const handleExecuteActionClick = (actionId: string) => {
        const action = availableActions
            .find(x => x.action.id === actionId);
        const hasUserInputFields = action!.action.fields
            .some(x => x.fieldType === 'UserInput') || false;

        if (hasUserInputFields) {
            // Display form
            tasksStore.setSelectedAction(actionId, pkg?.id);
        } else {
            // Execute action
            tasksStore.executeAction(tasksStore.taskPreview!.id, actionId, {}, action!.action.isClientSide, pkg?.id)
                .catch(err => console.log(err));
        }
    };
    
    const mainAppsMenu = [
        // <Menu.Item key="m1">Make dependent </Menu.Item>,
        // <Menu.Item key="m2">Add tags</Menu.Item>,
        // <Menu.Item key="m3">Follow-up task</Menu.Item>,
        // <Menu.Item key="m4">Duplicate task</Menu.Item>,
        <Menu.Item 
            data-id-type="popup-menu-list-item"
            key="m5"
            disabled={!onSendTaskToUsers}
            onClick={onSendTaskToUsers}
        >
            Email task to...
        </Menu.Item>
    ];

    const disabledForPreview = (fileName: string) => {
        return !['.pdf', '.xml', '.docx', '.xlsx', '.xlsm', 'txt', 'jpg', 'png'].some(ext=> fileName.toLocaleLowerCase().endsWith(ext));
    };

    const disabledForRemove = () => {
        const {status} = tasksStore.taskPreview!;
        const isFinalStatus = !!tasksStore.getStatusById(status)?.isFinal;
        return isFinalStatus;
    };

    const getPreviewMenu = (fileName: string) =>
        (
            <Menu.Item 
                data-id-type="popup-menu-list-item"
                key={pkg ? pkg.id + '1' : 'p1'} 
                onClick={() => onPreview(pkg!.id!)}
                disabled={disabledForPreview(fileName)}
            >
                Preview
            </Menu.Item>
        );

    const getActionsMenu = (attachment: PackagePreviewModel) => (
        <>
            {tasksStore.availableActionsLoading &&
                <Menu.Item>
                    <Skeleton.Button active size="small" block />
                </Menu.Item>
            }
            {availableActions.sort((a, b) => a.action.position - b.action.position).map(x =>
                <Menu.Item key={x.action.id} onClick={() => handleExecuteActionClick(x.action.id)} disabled={!x.canExecute}>
                    {x.action.title ?? x.action.name}
                </Menu.Item>)
            }
            {(tasksStore.availableActionsLoading || availableActions.length > 0) &&
                <Menu.Divider data-package-id={attachment.id} />
            }
        </>
    );

    const applicationsMenu = (
        <Menu data-id-name="task-applications" data-id-type="popup-menu-list-root">
            {pkg?.id && getActionsMenu(pkg)}
            {!pkg && mainAppsMenu.map( mi => mi )}
            {pkg?.id && getPreviewMenu(pkg.fileName)}
            <Menu.Item
                data-id="task-download-item"
                data-id-type="popup-menu-list-item"
                key="task-download-item"
                onClick={() => onDownload(pkg?.id)}
                disabled={disabled}
            >
                {isTaskMenu ? 'Download attachments' : 'Download'}
            </Menu.Item>
            <Menu.Item
                data-id="task-remove-item"
                data-id-type="popup-menu-list-item"
                key="task-remove-item"
                onClick={onRemoveAttachements}
                style={{ color: disabled ? '#00000040' : '#B80C02' }}
                disabled={disabled || disabledForRemove()}
            >
                Remove {pkg ? '' : 'attachments'}
            </Menu.Item>
            {isTaskMenu && <Menu.Item
                data-id="task-add-link"
                data-id-type="popup-menu-list-item"
                key="task-add-link"
                onClick={() => tasksStore.setSelectedLink(null)}
                disabled={tasksStore.taskFormLocked}
            >
                Link task
            </Menu.Item>}
            {onRemoveTask &&
                <Menu.Item
                    data-id="task-delete-item"
                    key="task-delete-item"
                    style={{ color: 'red' }}
                >
                    <Popconfirm
                        title="Are you sure that you want to delete this task?"
                        okText="Delete"
                        onConfirm={onRemoveTask}
                        cancelText="Cancel"
                    >
                        Delete task
                    </Popconfirm>
                </Menu.Item>
            }
        </Menu>
    );

    // packageId ? false : !tasksStore.canRunApplications
    return (
        <Dropdown
            data-id="new-contract-new-dropdown"
            overlay={applicationsMenu}
            placement="topLeft"
            trigger={['click']}
        >
            <Button
                data-id="new-contract-new-dropdown-button"
                type={type}
                size={size}
                style={btnStyle}
            >
                {label || 'New...'}
            </Button>
        </Dropdown>
    );
};

export default observer(TaskApplicationsMenu);